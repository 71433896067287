<template>
    <div style="text-align: left">
      <article>
        <section>
          <div class="container mt-5">
            <div class="mb-3">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/blogs' }">Blogs</el-breadcrumb-item>
                <el-breadcrumb-item>Edit Safari Blog</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <h1
              class="mb-4"
              style="font-size: 1.1em; font-weight: 600;"
            >Edit Safari Blog</h1>
            <div v-loading="isUploadingFile || isLoading">
              <el-form
                :model="safariBlogForm"
                :rules="packageRules"
                ref="safariBlogForm"
                class="demo-safariBlogForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Blog Title</label>
                    <el-form-item prop="title">
                      <el-input v-model="safariBlogForm.title" placeholder="Blog post title"></el-input>
                    </el-form-item>
                  </div>


                  
                  <div class="col-md-6" v-if="$store.state.currentRole == 'ADMIN'">
                    <label class="mt-3 mb-2">Author</label>
                    <el-form-item prop="destination_country" >
                      <el-select
                        v-model="safariBlogForm.author"
                        placeholder="Posted by"
                        style="width: 100%"
                      >
                       
                        <el-option 
                          v-for="user in users"
                          :key="user.id"
                          :label="user.fullname"
                          :value="user.fullname"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
  
                  </div>
                 
                </div>
  
                <div class="row">
                  
                  <div class="col-md-6">
                    <label class="mt-3 mb-2">Blog Image</label><br />
                    <el-form-item>
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >
                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                            imageUploadingProgress == 100
                              ? "Uploaded"
                              : "Uploading..."
                          }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>

                              <div class="col-md-6">
              <img :src="safariBlogForm.featured_image">
            </div>
              
                </div>
  
              
  
                <div class="row">
                  <div class="col-md-12"> 
                    <label class="mt-3 mb-2">Blog Content</label><br />
    <div id="editor" ref="editor" ></div>
  </div>
                </div>
  
              
  
                <hr />
                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="editSafariBlog('safariBlogForm')"
                  >Edit Safari Blog Post</el-button>
                </el-form-item>
              </el-form>
  
        
            </div>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  
  <script>
  import jwt_decode from "jwt-decode";
  
  
  // import BreadCrumbComponent from "../components/bread-crumb-component";
  
  // import TextEditor from "../reusable-text-editor/reusable-text-editor-component.vue";
  export default {
    components: {
    
      
      // BreadCrumbComponent,
    },
    data() {
      return {
        quill:null,
        addPackageModal: false,
        selectedFile: null,
        isUploadingFile: false,
        isLoading: false,
        value: "",
        content: "",
        loading: false,
        loadingError: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        
        safariBlogForm: {

          title: "",
          author: "",
          content: "",
          
          
        },
        safariBlog:{},
        fileLists: [],
        users: [],

        packageRules: {
          title: [
            {
              required: true,
              message: "Title is required",
              trigger: "blur",
            },
          ],
        
       
          author: [
            {
              required: true,
              message: "author is required",
              trigger: "blur",
            },
          ],

          
          content: [
            {
              required: true,
              message: "content is required",
              trigger: "blur",
            },
          ],
    
         
          
        },
      };
    },
  
    mounted() {

      this.setCurrentUser();
    
      
      const toolbarOptions = 
        [  
          
  ['bold', 'italic', 'underline', 'strike'],   // toggled buttons
  ['blockquote', 'code-block'],
  [{'header': 1}, {'header': 2}],              // custom button values
  [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'script': 'sub'}, {'script': 'super'}],     // superscript/subscript
  [{'indent': '-1'}, {'indent': '+1'}],         // outdent/indent
  [{'direction': 'rtl'}],                       // text direction

  [{'size': ['small', false, 'large', 'huge']}], // custom dropdown
  [{'header': [1, 2, 3, 4, 5, 6, false]}],

  [{'color': []}, {'background': []}],          // dropdown with defaults from theme
  [{'font': []}],
 
  [{'align': []}],
 
  ['clean'],   
  ['link', 'image', 'video'] 
                                  // remove formatting button
  
    ];

   
    this.quill = new Quill('#editor', {
      modules: {
        imageDrop: true,
        imageResize: {
          displaySize: true
        },
     
        toolbar: toolbarOptions, 
       
      },
      theme: 'snow',
      placeholder: "Detailed blog content"
    });
    // listen for image uploads
    this.quill.getModule('toolbar').handlers['image'] = this.imageHandler;

 
    this.quill.on('text-change', () => {
      this.content = this.quill.root.innerHTML
      
    })

    this.getUsers();
    this.getBlog();

    },
  
    methods: {
      imageHandler() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = () => {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          const range = this.quill.getSelection(true);
          const index = range.index + range.length;
          this.quill.insertEmbed(index, 'image', reader.result);
        }
        reader.readAsDataURL(file);
      };
    },
      handleClose(done) {
        done();
      },
  
      handlePreview(file) {
        console.log(file.raw);
        this.fileLists = [file];
        this.selectedFile = file.raw;
      },
      handleRemove() {
        this.selectedFile = null;
      },
  
      setCurrentUser() {
        let userToken = JSON.parse(localStorage.getItem("user"));
        let decoded = jwt_decode(userToken);
        this.$store.commit("setCurrentUsername", decoded.data.username);
        this.$store.commit("setCurrentFullName", decoded.data.fullname);
        this.$store.commit("setCurrentRole", decoded.data.role);
        this.$store.commit("setCurrentPhoto", decoded.data.image);
      },
  
      async editSafariBlog(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.isUploadingFile = true;
            if (this.selectedFile == null) {
              this.$notify({
                title: "Empty",
                message: "Profile picture is empty",
                type: "warning",
              });
            }
  
            try {
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("title", this.safariBlogForm.title);
              formData.append("author", this.safariBlogForm.author);
              formData.append("image", this.selectedFile);
              formData.append(
                "content",
                this.quill.root.innerHTML
              );
        
  
                 formData.append(
                "added_by",
               this.$store.state.userId
              );
            
              let request = await this.$http.post(
                `/safari_tours/edit_blog_post/${this.$route.params.id}`,
                formData,
                {
                  onUploadProgress: (progressEvent) => {
                    this.imageUploadingProgress = +(
                      (progressEvent.loaded / progressEvent.total) *
                      100
                    ).toFixed(0);
                  },
                }
              );
  
  
  
  
              
              if (
                request.data.success &&
                request.data.message == "BLOG_EDITED_SUCCESSFULLY"
              ) {
                this.addPackageModal = false;
                
                this.$notify({
                  title: "Success",
                  message: "Blog Edited Successfully",
                  type: "success",
                });
              } else if (request.data.message == "SAFARI_BLOG_EXISTS") {
                this.$notify({ 
                  title: "Not Found",
                  message: "Blog Doesn't Exists",
                  type: "warning",
                });
              } else throw "UNEXPECTED_RESPONSE";
            } catch (error) {
              if (error.message == "NetworkError") {
                this.isUploadingFile = false;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred please try again",
                  type: "warning",
                });
              }
              this.isUploadingFile = false;
              this.$notify({
                title: "Upload Failed",
                message: "Unable to Upload profile picture now, Please try again",
                type: "error",
              });
            } finally {
              this.isUploadingFile = false;
            }
          } else {
            return false;
          }
        });
      },
  

 

   async getBlog() {
     this.isLoading = true;
     this.isLoadingError = false;
     try {
       const response = await this.$http.get(`/safari_tours/get_blog_post/${this.$route.params.id}`);
       if (response.data.success) {
         this.safariBlog = response.data.blog_post;
         this.safariBlogForm.title = this.safariBlog.title;
         this.safariBlogForm.author = this.safariBlog.author;
         this.safariBlogForm.content = this.safariBlog.content;
         // set the editor's root inner HTML to the saved content
         this.quill.root.innerHTML = this.safariBlog.content;
         this.selectedFile = this.safariBlog.featured_image;
       } else {
         throw new Error("Unexpected response received");
       }
     } catch (error) {
       this.isLoadingError = true;
       this.$notify({
         title: "Operation Failed",
         message: "Unable to fetch blog now, please try again",
         type: "error",
       });
       throw error; // rethrow for the caller to handle
     } finally {
       this.isLoading = false;
     }
   },
   
      async getUsers() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`api/user/get_all`);
        if (request.data.success) {
          this.users = request.data.users;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Users now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    },
  };
  </script>
  
  <style scoped>
  .addBtn {
    background-color: green;
    color: white;
  }
  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
  }
  
  label{
    font-size: 0.9em; font-weight: 600; color:#2a5e42
  }
  
  .search_add_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .search_by_input {
    width: 400px !important;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
    .search_by_input {
      width: 100% !important;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
    .search_by_input {
      width: 100% !important;
    }
  }
  </style>
  
  <style>
  .safari_dialogs .el-dialog {
    width: 60%;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .safari_dialogs .el-dialog {
      width: 60%;
    }
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .safari_dialogs .el-dialog {
      width: 70%;
    }
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .safari_dialogs .el-dialog {
      width: 90%;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .safari_dialogs .el-dialog {
      width: 90%;
    }
  }
  </style>
  